import * as React from "react"
import { Link, graphql } from 'gatsby'

// markup
const TrainerPage = (props) => {
  const trainer = props.data.trainer
  const workouts = props.data.allWorkout.nodes
  
  return (
    <main>
      <h1>{trainer.firstName}</h1>
      {workouts.map(workout => {
        return (
          <li key={workout.id}><Link to={workout.workoutPath}>{workout.title}</Link></li>
        )
      })}
    </main>
  )
}

export default TrainerPage

export const query = graphql`
# $trainerId is pased via "context" in gatsby-node.js using exports.createPages
query($id: String) {
  trainer(id: {eq: $id}) {
    id
    firstName
  }
  allWorkout(filter: {createdBy: {eq: $id}}) {
    nodes {
      id
      title
      workoutPath: gatsbyPath(filePath: "/workouts/{Workout.id}")
    }
  }
}
`